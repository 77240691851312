.page-content {
    min-height: 520px;
}

.question-container {
    width: 95%;
}

@media (min-width: 500px) {
    .question-container {
        width: 90%;
    }
}

@media (min-width: 1000px) {
    .question-container {
        width: 70%;
    }
}

@media (min-width: 1500px) {
    .question-container {
        width: 50%;
    }
}