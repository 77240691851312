.indications-content {
    min-height: 520px;
    width: 80% !important;
}

@media (max-width: 500px) {
    .indications-content {
        width: 100% !important;
    }
}
