.page-content {
    min-height: 520px;
}

.contract-container {
    text-align: justify;
    font-family: Arial, Helvetica, sans-serif !important;
    color: black !important;
}

.contract-container span p h1 h2 h3 h4 h5 li {
    color: black !important;
}
