.details-content {
    min-height: 520px;
    width: 100% !important;
    font-size: larger;
}

.scholarship-details-description-title {
    font-size: large;
}

@media (max-width: 500px) {
    .defails-content {
        width: 100% !important;
    }
}