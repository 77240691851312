.sub-header {
    position: relative;
    overflow: hidden;
    height: 150px;
}

.sub-header h1 {
    position: relative;
    z-index: 2;
}

.img-sub-header-bg {
    background-image: url('../../Images/page-title.webp');
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 150px;
    opacity: 0.3;
}