.aviso-cookie {
    z-index: 799;
}

.aviso-cookie {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 20px;
    text-align: center;
}

.aviso-cookie-box {
    display: inline-flex;
    align-items: center;
    max-width: calc(100% - 40px);
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 13px;
    line-height: 1.5em;
    font-weight: 400;
    background-color: #fff;
    color: #5d5d5d;
    box-shadow: 0 0 4px 4px #3d3b3b26;
}

.aviso-cookie-texto {
    padding: 0 15px 0 5px;
    text-align: left;
}

