.page-content {
    min-height: 520px;
}

.hover-shadow {
    transition: .3s ease;
  }
  
  .hover-shadow:hover {
    box-shadow: 0px 4px 25px 0px rgba(27, 39, 71, 0.15);
  }