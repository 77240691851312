.first-header {
    background-color: white
}

.page-content {
    width: 100%;
}

.payment-item {
    transition: 0.2s
}

.payment-item:hover {
    -webkit-box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.5);
    transition: 0.2s
}

@media (min-width: 500px) {
    .page-content {
        width: 100% !important;
    }
}