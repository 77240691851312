.login-btn {
    color: rgb(189, 189, 189)
}

.features-container {
    background-color: #F6F6F6;
}

.feature-icon {
    font-size: 50px;
    color: #ff9d1c;
    display: inline-block;
}

.filter-box {
    border-radius: 25px;
    background-color: rgba(255, 255, 255, 0.85);
}

.select2-container {
    background-color: white;
}

.filter-box select {
    border-radius: 25px;
    background-color: rgb(255, 255, 255);
}

.selected-category-name {
    font-size: 32px;
    font-weight: 600;
    color: coral;
}

.scholarship-card {
    text-align: center;
    height: 100%;
}

.scholarship-card-header {
    max-height: 100px;
}

.home-filter-root-container {
    /* background-image: url("/img/city2.webp");
    background-size: cover;
    */
}

.filter-text-container {
    position: absolute !important;
    left: 50%;
    top: 30%;
}

.linha {
    color: white;
    font-weight: bold;
}

.linha-1 {
    font-size: 42px;
    display: block;
    text-shadow: 2px 2px #0000005e;
    line-height: 42px;
}

.linha-2 {
    font-size: 42px;
    display: block;
    text-shadow: 2px 2px #0000005e;
    line-height: 42px;
}

.linha-3 {
    font-size: 42px;
    line-height: 42px;
    text-shadow: 2px 2px #0000005e;
}

.maior {
    font-size: 82px;
    line-height: 82px;
    color: coral;
    text-shadow: 2px 2px #0000005e;
}

.arrows {
    font-size: 68px;
    line-height: 68px;
    color: coral;
    text-shadow: 2px 2px #0000005e;
}

.linha-4 {
    font-size: 42px;
    line-height: 28px;
    display: block;
    text-shadow: 2px 2px #0000005e;
}

.filter-col {
    position: absolute !important;
}

.filter-model-col {
    position: absolute !important;
    bottom: 0;
    right: 0;
}

.img-model {
    -webkit-transition: all 2s ease-in-out;
    transition: all 2s ease-in-out;
}

.background-container {
    height: 87vh !important;
    background-size: cover;
    background-color: rgba(0, 0, 0, 0.774);
    -webkit-transition: background-image 0.3s ease-in-out;
    transition: background-image 0.3s ease-in-out;
}

.category-option {
    border-style: solid;
    border-width: 0.3px 0px 0px 0px;
    border-color: rgb(0,0,0, 0.5);
    font-weight: 600;
    font-size: 16px;
}

.filter-type-text {
    font-size: 14px;
}

.sub-category-radio-label {
    font-size: 14px;
}

.category-option.selected {
    background-color: rgba(255, 255, 255, 0.85);
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
}

.category-option.selected label, .category-option.selected i {
    color: coral;
}

.category-option input {
    display: none;
}

.category-option:hover label {
    text-decoration: underline;
    cursor: pointer;
}

.category-option label {
    margin-bottom: 0 !important;
}

.img-container {
    width: 200px;
    height: 200px;
}

.img-container img {
    width: 200px;
    height: 200px;
    object-fit: contain
}

.slick-track {
    display: flex !important;
}

.slick-slide {
    height: inherit !important;
}

.slick-slide>div:first-child {
    height: 100% !important;
}

@media (max-width: 768px) {
    .filter-box {
        border-radius: 25px;
        background-color: white;
        border-style: solid;
        border-color: rgba(0, 0, 0, 0.568);
        border-width: 0.3px 0px 0px 0px;
        border-radius: 0px;
    }
    .filter-col {
        position: relative !important;
        padding-top: 10px !important;
    }
    .background-container {
        height: 200px !important;
    }
    .filter-model-col {
        width: 130px;
    }
    .category-option {
        border-style: none;
    }
    .category-option input {
        display: inline;
    }
    .category-option label {
        color: #2e5574;
        text-align: left;
        margin-left: 10px;
        font-weight: 400;
    }
    .category-option.selected label, .category-option.selected i {
        color: #2e5574;
        font-weight: bold;
        text-decoration: none;
    }
    .filter-text-container {
        position: absolute !important;
        left: 10%;
        top: 30%;
    }
    .linha-1 {
        font-size: 18px;
        display: block;
        text-shadow: 2px 2px #0000005e;
        line-height: 38px;
    }
    .linha-2 {
        font-size: 18px;
        display: block;
        text-shadow: 2px 2px #0000005e;
        line-height: 0px;
    }
    .linha-3 {
        font-size: 18px;
        line-height: 0px;
        text-shadow: 2px 2px #0000005e;
    }
    .linha-4 {
        font-size: 18px;
        line-height: 0px;
        text-shadow: 2px 2px #0000005e;
    }
    .arrows {
        font-size: 24px;
        line-height: 52px;
    }
    .maior {
        font-size: 42px;
        line-height: 0px;
    }
}

@media (min-width: 1700px) {
    .filter-text-container {
        left: 40%;
    }
}

.slider-container {
    height: 520px;
  }
  
