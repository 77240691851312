.root-container {
    padding: 0 !important;
    height: 100vh;
}

.content-container {
    border-style: solid;
    border-width: 1px;
    border-color: black;
}

.number-item {
    border-radius: 75px;
    width: 70px;
    height: 70px;
}

@media (min-width: 1200px) {
    .root-container {
        padding-left: 20vh !important;
        padding-right: 20vh !important;
    }
}