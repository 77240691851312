.select2 {
    width: 100%!important;
}

.image-container {
    background-color: white;
    border-right: rgb(218, 218, 218) solid 1px;
}

.scholarship-item {
    min-height: 150px;
    transition: 0.2s
}

.institution-image {
    max-height: 150px
}

.scholarship-title {
    font-size: 24px;
}

.discount-container {
    border-bottom-right-radius: 10px;
    border-top-left-radius: 10px;
}

.full_price {
    color: #8a8a8a;
}

.scholarship-final-price-small {
    font-size: small;
}

.know-more-btn {
    max-width: 140px;
}

.scholarship-item:hover {
    -webkit-box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 7px -2px rgba(0, 0, 0, 0.5);
    transition: 0.2s
}

.btn-buy-scholarship {
    min-height: 50px
}

.cart-icon {
    font-size: 24px;
}

.scholarship-item .text-small {
    font-size: 12px;
}

.scholarship-item .text-extra-small {
    font-size: 11px;
}

.scholarship-info {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: black;
}

@media (max-width: 768px) {
    .full_price {
        display: block;
    }
    .know-more-btn {
        width: 100%;
        max-width: 100px;
    }
    .know-more-btn span,i {
        font-size: 12px;
    }
    .scholarship-final-price-small {
        font-size: large;
    }
    .scholasrhip-discount-container h5{
        font-size: x-large;
    }
}

@media (min-width: 500px) {
    .form-filter {
        position: sticky;
        top: 160px;
    }
}