@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,700,900&display=swap');
html, body {
    font-family: "Poppins", 'Montserrat', sans-serif;
    -webkit-font-smoothing: antialiased;
    color: #14496b;
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}

#root {
    height: 100%;
}

.text-white {
    color: white;
}

.text-blue {
    color: rgb(55, 26, 182)
}

.text-yellow {
    color: rgb(197, 185, 12)
}

.text-green {
    color: rgb(3, 211, 124)
}

.form-control {
    height: 60px !important;
    background: #fff;
    border-radius: 0;
    padding-left: 25px;
    text-transform: uppercase;
}

.form-control:focus {
    border-color: #ff9d1c;
    box-shadow: unset
}

h1, h2, h3, h4, h5 {
    color: #14496b;
    font-family: "futura-bold";
    font-weight: 700;
    line-height: 1.2;
}

p {
    font-weight: 400;
    color: #5c5c77;
    font-size: 15px;
    line-height: 1.9;
    font-family: Futura, sans-serif;
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.spacer {
    height: 2px !important;
}

.hr {
    height: 1px;
    width: 100%;
    background-color: rgb(44, 44, 44);
    opacity: 0.2;
}

.text-grey {
    color: rgb(134, 134, 134);
}

.text-extra-small {
    font-size: 12px;
}

.wpp-color {
    color: #00c864
}

ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
}

ol>li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
}

ol>li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
}

li ol>li {
    margin: 0;
}

li ol>li:before {
    content: counters(item, ".") " ";
}

@media print {
    .no-print, .no-print * {
        display: none !important;
    }
}