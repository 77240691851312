.info-box {
    -webkit-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}

.scholarship-details-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    font-size: 26px;
}

.scholarship-info-header {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.info-label {
    min-width: 200px !important;
}

.scholarship-full-price {
    font-size: 12px;
}

.capitalize {
    text-transform: capitalize;
}

.link-azul {
    color: rgb(103, 103, 255) !important;
  }
  