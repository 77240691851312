$theme-colors: (
  "primary": #14496b,
  "secondary": #ff9d1c
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1700px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1610px
);

@import "../../node_modules/bootstrap/scss/bootstrap";
$theme-colors: (
  "primary": #14496b,
  "secondary": #ff9d1c
);

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1700px
);

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 960px,
        xl: 1140px,
        xxl: 1610px
);

@import "../../node_modules/bootstrap/scss/bootstrap";