.about-content {
    min-height: 520px;
    width: 80% !important;
    font-size: larger;
}

@media (max-width: 500px) {
    .about-content {
        width: 100% !important;
    }
}