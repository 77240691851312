.promotion-header-container {
    height: 100px;
    background-color: #7123bb;
}

.promotion-icon {
    color: #ff9d1c;
    width: 50px;
    height: 50px;
}
figcaption {
    color: white !important;
}

#countdown {
    color: white;
}

.countdown-container {
    background-color: #571a91;
}

.dropdown-toggle::after {
    display: none !important
}

.first-header {
    background-color: white;
}

.top-menu {
    position: sticky;
    top: 0;
    z-index: 1020;
}

.nav-link {
    color: white !important;
    text-align: center !important;
    font-weight: bold !important;
}

.navbar-expand-lg {
    justify-content: center !important;
}

.sub-menu {
    position: absolute;
    width: 100%;
}

.categories-list {
    background-color: #ff9d1c;
    min-width: 250px;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}

.category-item {
    padding: 10px;
    transition: 0.3s;
    border-color: rgb(0, 0, 0, 0.1);
    border-width: 1px;
    border-style: solid;
}

.category-item:hover {
    background-color: #14496b;
}

.category-item a {
    color: white;
}

.category-item a:hover {
    color: white;
}

.btn-close-modal:hover {
    cursor: pointer;
}

@media (max-width: 500px) {
    .promotion-header-container {
        height: 120px;
    }
    .top-menu {
        position: relative;
        z-index: 1020;
    }
    .categories-list {
        width: 100%;
    }
    .sub-menu {
        position: relative;
        width: auto !important;
    }
    .category-item {
        text-align: center;
    }
    .categories-submenu {
        margin: 0;
        padding: 0 !important;
    }
    .wpp-text {
        font-size: 13px;
        font-weight: bold;
    }
    .fa-whatsapp {
        font-size: 16px !important;
    }
    .fa-user-alt {
        font-size: 12px !important;
    }
    .login-btn {
        font-size: 12px !important;
    }
    .btn-login span {
        font-size: 13px;
    }
}